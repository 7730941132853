<script>
import {fliggyAttributesKey, fliggyRelationTypeOptions} from '@/data/workOrder'
import {download} from "@/common/js/Utils";
import dayjs from "dayjs";

export default {
  name: "MessagePanel",
  props: ['info'],
  data() {
    return {
      fliggyRelationTypeOptions
    }
  },
  computed: {
    list() {
      return this.info ? this.info.fliggyCaseContentDtos.map((item) => {
        const attributes = JSON.parse(item.attributes)
        return {
          ...item,
          attributes,
          content: attributes[fliggyAttributesKey[item.relationType]],
          imgUrlList: attributes['imgUrlList'],
          fileUrlList: attributes['fileUrlList'],
        }
      }) : []
    }
  },
  methods: {
    handleDownload(file, fileName) {
      download(file, fileName + '-' + dayjs().format('YYYY-MM-DD HH:mm:ss'))
    }
  }
}
</script>

<template>
  <div class="fliggy-message-panel">
    <p class="color-second font_s14">{{ info.relationType === 'notify' ? '消息通知' : '协同历史' }}</p>
    <div class="mt10 fliggy-message-panel_content">
      <div v-for="vm in list" :key="vm.id" class="mb10">
        <p class="color-gary font_s14">
          <span class="mr10">{{ vm.createTime | dateCus }}</span>
          <span class="ml20">{{vm.createType===1?'900-':'飞猪-'}}{{ vm.createName }}：<span v-if="!['replied','startRelation'].includes(vm.relationType)">{{
              vm.relationType | formatDesc(fliggyRelationTypeOptions)
            }}</span><span v-else>已回复</span></span>
        </p>
        <div class="message-content">
          <p class="font_s14">{{ vm.content }}</p>
          <p style="display: flex;margin-top: 10px">
            <el-image v-for="img in vm.imgUrlList" :key="img"
                      style="width: 60px;max-height: 60px;margin: 0 5px"
                      :src="img" :preview-src-list="[img]"></el-image>
          </p>
          <p v-for="(file,index) in vm.fileUrlList" :key="file" class="mt10">
            <el-link type="primary" @click="handleDownload(file, '附件'+(index + 1))">
              附件{{ index + 1 }}
            </el-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.fliggy-message-panel {
  padding: 0 15px;
}

.message-content {
  min-height: 40px;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  margin-top: 5px;
}

.fliggy-message-panel_content {
  height: 368px;
  overflow: hidden;
  overflow-y: scroll;
}
</style>
