<script>
import {replyFliggyCaseApi} from "@/www/urls/workOrder";
import {fliggyCategoryOptions, fliggyAttributesKey} from "@/data/workOrder";
import ImageUpload from "@/components/imageUpload/index.vue";
import FileUploadMultiple from "@/components/fileUploadMultiple/index.vue";
import dayjs from "dayjs";

const initForm = () => {
    return {
        relationType: 'reply',
        categoryId: '',
        categoryName: '',
        images: [],
        files: [],
    }
}
export default {
    name: "ReplyMessage",
    props: ['info'],
    components: {
        ImageUpload,
        FileUploadMultiple
    },
    data() {
        return {
            upFileKey: '/fliggyWorkOrder/' + dayjs().format('YYYY-MM') + '/' + dayjs().valueOf() + '/',
            fliggyCategoryOptions,
            form: initForm(),
            relationTypeOptions: [
                {
                    value: 'reply',
                    label: '回复'
                },
                // {
                //     value: 'urgeCase',
                //     label: '催促'
                // },
                // {
                //     value: 'notify',
                //     label: '消息通知'
                // },
                // {
                //     value: 'result',
                //     label: '结果通知，将结束协同'
                // }
            ],
            rules: {
                content: [{required: true, message: '请输入需要协同处理的问题或内容'}]
            },
            loading: false
        }
    },
    methods: {
        handleCancel() {
            this.$emit('onCancel')
        },
        async handleSubmit() {
            try {
                this.loading = true;
                await this.$refs['form'].validate();
                const attributes = {};
                attributes[fliggyAttributesKey[this.form.relationType]] = this.form.content;
                if (this.form.images.length)
                    attributes['imgUrlList'] = this.form.images.map(({imageUrl}) => imageUrl).filter((item) => item);
                if (this.form.files.length)
                    attributes['fileUrlList'] = this.form.files;
                const f = {
                    id: this.info.id,
                    fliggyCaseContent: JSON.stringify(attributes)
                }
                console.log(f);
                const ret = await replyFliggyCaseApi(f)
                if (ret.success && ret.data) {
                    this.$message.success('回复成功！');
                    this.form = initForm();
                    this.$emit('onSuccess')
                    window.bus.$emit('refreshFliggyCount')
                } else {
                    this.$message.error(ret.errors[0].message)
                }
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<template>
    <div class="fliggy-send-message">
        <p class="color-second mb15 font_s14">回复协同</p>
        <el-form :model="form" :rules="rules" ref="form" size="small" label-width="95px">
            <el-form-item label="协同类型：">
                <el-radio-group v-model="form.relationType">
                    <el-radio-button v-for="vm in relationTypeOptions" :key="vm.value" :label="vm.value">
                        {{ vm.label }}
                    </el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="协同问题：" prop="content">
                <el-input type="textarea" v-model="form.content"
                          placeholder="请输入需要协同处理的问题或内容" style="width: 400px;" rows="5"></el-input>
            </el-form-item>
            <el-form-item label="上传图片：">
                <image-upload :upImgKey="upFileKey" :max="9" width="60px" height="60px"
                              @update:imageUploadCompleted="form.images=$event"></image-upload>
            </el-form-item>
            <el-form-item label="上传文件：">
                <div style="width: 400px">
                    <fileUpload-multiple :upImgKey="upFileKey" v-model="form.files" accept="*"></fileUpload-multiple>
                </div>
            </el-form-item>
            <el-form-item style="display: flex;justify-content: flex-end">
                <el-button type="primary" plain :loading="loading" @click="handleCancel">取消</el-button>
                <el-button type="primary" :loading="loading" @click="handleSubmit">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<style scoped lang="scss">
.fliggy-send-message {
    padding: 0 15px;

    &::v-deep {
        .el-form-item {
            margin-bottom: 18px !important;
        }
    }
}
</style>
