<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <com-list-search>
                <el-form :inline="true" slot="list-search-before" size="medium">
                    <el-form-item>
                        <el-radio-group v-model="form.relationType">
                            <el-radio-button
                                v-for="vm in [{label:'全部',value:-1},...fliggyRelationTypeOptions]" :key="vm.label"
                                :label="vm.value">
                                {{ vm.label }} <span
                                v-if="!['replied','endRelation','read'].includes(vm.value)"
                                class="ml5 color-danger">{{ vm.count }}</span>
                            </el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                        <el-input placeholder="订单号" style="width: 140px" clearable
                                  v-model="form.orderNo"/>
                    </el-form-item>
                    <el-form-item>
                        <el-input placeholder="飞猪订单号" style="width: 140px" clearable
                                  v-model="form.thirdOrderId"/>
                    </el-form-item>
                    <el-form-item>
                        <el-input placeholder="飞猪工单号" style="width: 140px" clearable
                                  v-model="form.thirdCaseId"/>
                    </el-form-item>
                </el-form>
                <div slot="list-search-after" style="width: 170px;display: flex;justify-content: flex-end">
                    <el-button @click="resetClick()">重置</el-button>
                    <el-button type="primary" @click="getList()" :loading="loading">搜索</el-button>
                </div>
            </com-list-search>
            <SectionContent v-if='result.total'>
                <div v-for="(vm,index) in result.data" :key="vm.id" class="fliggy-list">
                    <el-table :data="[vm]" border :show-header="index===0">
                        <el-table-column label="序号" type="index" width="50" align="center">
                            <template>
                                {{ index + 1 }}
                            </template>
                        </el-table-column>
                        <el-table-column label="订单号" prop="orderNo" align="center" width="120">
                            <template slot-scope="scope">
                                <el-link type="primary" :href="'#/userCarList?orderNo='+scope.row.orderNo"
                                         target="_blank">
                                    {{ scope.row.orderNo }}
                                </el-link>
                            </template>
                        </el-table-column>
                        <el-table-column label="飞猪订单号" prop="thirdOrderId" align="center"
                                         width="180"></el-table-column>
                        <el-table-column label="飞猪工单号" prop="thirdCaseId" align="center"
                                         width="180"></el-table-column>
                        <el-table-column label="协同问题分类" prop="categoryName" align="center"></el-table-column>
                        <el-table-column label="更新时间" prop="updateTime" width="160">
                            <template slot-scope="scope">
                                <div style="position: relative">
                                    {{ (scope.row.updateTime || scope.row.createTime) | dateCus }}
                                    <div class="mark"
                                         v-if="['notify','startRelation','reply','urgeCase'].includes(scope.row.relationType)">
                                        NEW
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="协同类型" prop="relationType" align="center" width="120">
                            <template slot-scope="scope">
                                <el-tag
                                    :type="scope.row.relationType | formatDesc(fliggyRelationTypeOptions,'value','type')">
                                    {{ scope.row.relationType | formatDesc(fliggyRelationTypeOptions) }}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="120">
                            <template slot-scope="scope">
                                <el-link type="primary" :underline="false"
                                         @click="handleCheck(scope.row,index)">
                                    查看{{
                                        ['notify', 'endRelation', 'read'].includes(scope.row.relationType) ? '' : '/回复'
                                    }}
                                </el-link>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="message-box" v-if="showIndex===index">
                        <div style="flex: 1;">
                            <MessagePanel :info="vm"></MessagePanel>
                        </div>
                        <div style="flex: 1;" v-if="!['notify','read', 'endRelation'].includes(vm.relationType)">
                            <ReplyMessage :info="vm" @onCancel="showIndex=undefined"
                                          @onSuccess="getDetail(vm.id)"></ReplyMessage>
                        </div>
                    </div>
                </div>
            </SectionContent>
            <el-empty v-else></el-empty>
        </el-main>
        <el-footer style="display: flex;justify-content: flex-end;align-items: center">
            <el-pagination background layout="prev, pager, next"
                           :current-page="form.pageIndex"
                           :total="result.total"
                           @current-change="getList">
            </el-pagination>
        </el-footer>
        <WorkOrderDetailDialog ref="workOrderDetailDialogRef" @onChange="handleOnChange"></WorkOrderDetailDialog>
    </div>
</template>

<script>
import {
    workOrderStatusOptions,
    workOrderTypeOptions,
    appealStatusOptions,
    fliggyRelationTypeOptions
} from '@/data/workOrder'
import comListSearch from "@/www/components/comListSearch/index.vue";
import SectionContent from "@/components/sectionContent/index.vue";
import dayjs from "dayjs";
import {
    fliggyCaseReadApi,
    getFliggyCaseDetailApi,
    getFliggyCaseListApi,
    getFliggyCaseStatusCountApi
} from "@/www/urls/workOrder";
import WorkOrderDetailDialog from "@/www/pages/workOrder/WorkOrderDetailDialog.vue";
import MessagePanel from "@/www/pages/workOrder/fliggy/MessagePanel.vue";
import ReplyMessage from "@/www/pages/workOrder/fliggy/ReplyMessage.vue";
import {combinationForm} from "@/common/js/common";

const createForm = () => ({
    pageIndex: 1,
    pageSize: 10,
    orderNo: undefined,
    relationType: -1,
})
export default {
    name: "workOrderFliggy",
    data() {
        return {
            workOrderStatusOptions,
            workOrderTypeOptions,
            appealStatusOptions,
            loading: true,
            form: createForm(),
            result: {
                data: [],
                total: 0
            },
            showIndex: undefined,
            fliggyCaseStatusCount: []
        }
    },
    filters: {
        workOrderStatusOptionsFormat(value, key, record) {
            if (value === 0 && record.createType === 0)
                return key === 'label' ? '已发送' : 'primary';
            return workOrderStatusOptions.find((item) => item.value === value)[key]
        }
    },
    components: {
        comListSearch,
        SectionContent,
        WorkOrderDetailDialog,
        MessagePanel,
        ReplyMessage
    },
    computed: {
        fliggyRelationTypeOptions() {
            return fliggyRelationTypeOptions.map((item) => ({
                ...item,
                count: ((this.fliggyCaseStatusCount.find(({relationType}) => relationType === item.value) || {}).count || 0)
            }))
        }
    },
    mounted() {
        this.getList();
    },
    activated() {
        if (this._orderNo !== this.$route.query.orderNo) {
            this._orderNo = this.$route.query.orderNo;
            this.form.orderNo = this._orderNo;
        }
        this.getList(1)
        this.getFliggyCaseStatusCount();
    },
    methods: {
        showEdit(record) {
            return record.status === 0 && dayjs(record.pushTime).isAfter(dayjs())
        },
        async getFliggyCaseStatusCount() {
            try {
                this.loading = true
                const ret = await getFliggyCaseStatusCountApi()
                if (ret.success)
                    this.fliggyCaseStatusCount = ret.data
            } finally {
                this.loading = false
            }
        },
        async getList(page = 1) {
            this.form.pageIndex = page;
            this.showIndex = undefined;
            this.loading = true
            const ret = await getFliggyCaseListApi({
                ...combinationForm(this.form),
                relationType: this.form.relationType === -1 ? undefined : this.form.relationType
            })
            this.loading = false
            if (ret.success) {
                this.result = ret.data;
            } else this.$message.error(ret.errors[0].message)
            if (page === 1)
                this.getFliggyCaseStatusCount();
        },
        resetClick() {
            this.form = createForm();
            this.getList(1)
        },
        handleOnChange(record) {
            this.result.data = this.result.data.map((item) => ({
                ...item,
                ...(record.id === item.id ? record : {})
            }))
        },
        async getDetail(id) {
            try {
                this.loading = true
                const ret = await getFliggyCaseDetailApi({
                    id
                })
                if (ret.success) {
                    this.handleOnChange(ret.data)
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        },
        async handleCheck(record, index) {
            this.showIndex = this.showIndex === index ? null : index;
            if (record.relationType === 'notify') {
                try {
                    const ret = await fliggyCaseReadApi({
                        id: record.id,
                        fliggyCaseContent: JSON.stringify({
                            content: '已读'
                        }),
                        thirdOrderId: record.thirdOrderId,
                        relationType: 'read'
                    })
                    if (ret.success) {
                        this.getFliggyCaseStatusCount();
                        this.result.data[index].relationType = 'read';
                        window.bus.$emit('refreshFliggyCount')
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.common-list-search::v-deep {
    .list-search-before .el-form-item.el-form-item--small {
        width: auto;
    }
}

.fliggy-list::v-deep {
    .el-table__cell:nth-child(6) {
        .cell {
            overflow: visible;
        }
    }
}

.list-item {
    border: 1px solid #f2f4f6;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    background-color: #fff;

    &:hover {
        box-shadow: 0 0 12px 4px rgba(16, 34, 71, .1);
        -webkit-box-shadow: 0 0 12px 4px rgba(16, 34, 71, .1);
        -webkit-transform: translateY(-2px);
        -ms-transform: translateY(-2px);
        transform: translateY(-2px);
        -webkit-transition: all .2s ease-out;
        transition: all .2s ease-out;
    }

    .list-item_hd {
        border-bottom: 1px solid #f2f4f6;
        display: flex;
        padding-bottom: 10px;
    }

    .list-item_bd {
        padding-top: 5px;
        display: flex;

        & > div:not(:first-child) {
            flex: 1;
        }

        & > div:first-child {
            width: 50%;
            padding-right: 50px;
        }
    }
}

.message-box {
    display: flex;
    padding: 10px 0;
    background-color: #F0F8FF;
    border: 1px solid #87C3FF;
    position: relative;
}

.mark {
    background-color: #FF0000;
    border-radius: 7.5px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    color: #fff;
    position: absolute;
    right: 10px;
    top: -12px;
    z-index: 999;
    font-size: 10px;
}
</style>
